import { Navigator, PossibleNavigatorValue, RangeNavigator } from '@bbx/search-journey/common/Navigators'
import type { useModalNavigator } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/MultiSelectNavigator/useModalNavigator'
import { PossibleLabelContent } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/NavigatorValue'
import { CheckboxStateType, getValueId, showHitsLabel } from '@bbx/search-journey/sub-domains/search/lib/navigator-functions'
import { Box } from '@wh-components/core/Box/Box'
import { Checkbox, CheckboxSize } from '@wh-components/core/FormElements/Checkbox/Checkbox'
import { Heading } from '@wh-components/core/Heading/Heading'
import React, { FunctionComponent, ReactNode } from 'react'
import { css } from 'styled-components'

type Props = {
    groupName?: string | null
    possibleValues: PossibleNavigatorValue[]
    navigator: Navigator | RangeNavigator
    checkboxState: CheckboxStateType
    checkboxSize?: CheckboxSize | undefined
    checkedContent?: (possibleValue: PossibleNavigatorValue) => React.ReactNode
    uncheckedContent?: (possibleValue: PossibleNavigatorValue) => React.ReactNode
    renderLabel?: (label: string) => ReactNode
    disabled?: boolean
} & Pick<ReturnType<typeof useModalNavigator>, 'handleChange'>

export const GroupedPossibleValues: FunctionComponent<Props> = ({
    groupName,
    possibleValues,
    checkboxState,
    handleChange,
    navigator,
    checkboxSize,
    checkedContent,
    uncheckedContent,
    renderLabel,
    disabled,
}) => {
    const flexColumns =
        checkboxSize === 'touchscreen'
            ? { phone: '0 1 25%', desktop: '0 1 20%' }
            : { phone: '0 1 100%', tablet: '0 1 50%', desktop: '0 1 33.33%' }
    return (
        <Box marginBottom="s" testId={`modal-group-${groupName}-container`}>
            {groupName && (
                <Box marginBottom="xs">
                    <Heading level={3} fontSize="m" text={groupName} testId={`modal-group-${groupName}`} />
                </Box>
            )}
            <Box display="flex" flexWrap="wrap">
                {possibleValues.map((possibleValue) => {
                    return (
                        <Checkbox
                            flex={flexColumns}
                            key={getValueId(possibleValue)}
                            customCheckboxContent={
                                uncheckedContent && checkedContent
                                    ? () => ({
                                          unchecked: uncheckedContent(possibleValue),
                                          checked: checkedContent(possibleValue),
                                      })
                                    : undefined
                            }
                            label={
                                <Box paddingLeft={2}>
                                    <PossibleLabelContent
                                        label={renderLabel ? renderLabel(possibleValue.label) : possibleValue.label}
                                        showHitsLabel={showHitsLabel(navigator)}
                                        hits={possibleValue.hits}
                                        disabled={false}
                                    />
                                </Box>
                            }
                            marginBottom={{ phone: 'xs', tablet: 0 }}
                            id={`${navigator.id}-${groupName}-checkbox-modal-${possibleValue.label}-${possibleValue.parentLabel}`}
                            testId={`${navigator.id}-${groupName}-checkbox-modal-${possibleValue.label}-${possibleValue.parentLabel}`}
                            checked={checkboxState[getValueId(possibleValue)] || false}
                            onChange={(e) => handleChange(getValueId(possibleValue), e.target.checked)}
                            css={css`
                                break-inside: avoid;

                                @-moz-document url-prefix() {
                                    label:before {
                                        top: 0.5px;
                                    }
                                }
                            `}
                            size={checkboxSize}
                            labelPosition={checkboxSize === 'touchscreen' ? 'bottom' : 'right'}
                            disabled={disabled}
                        />
                    )
                })}
            </Box>
        </Box>
    )
}
